import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import { PageHeader } from '../components/PageHeader';
import { Image } from '../components/Image'
import PostList from '../components/Blog/PostList';
import { decodeEntities } from '../utils/helpers';
import { Breadcrumbs } from '../components/Breadcrumbs';

export default class IndexPage extends React.Component {
  render() {
    const { data, pageContext, location } = this.props;
    const { thisCategoryPost, allWordpressPost, categoryFilter, categories, wordpressWpSettings, siteSettings } = data;
    const { showAuthor } = siteSettings.options
    const { title: siteTitle, date_format } = wordpressWpSettings;
    const { edges: posts } = allWordpressPost
    const { description, acf, yoast } = thisCategoryPost
    const { title, subTitle } = acf
    const headerTitle = title ? title : 'Blog'
    const featuredImage = acf && acf.featureImage && acf.featureImage.source_url ? acf.featureImage : ''
    return (
      <Layout location={location}>
        <SEO
          title={`${
            yoast.metaTitle ?
            yoast.metaTitle :
            `Blog | ${decodeEntities(siteTitle)}`
          }`}
          desc={yoast.metaDescription}
          yoast={yoast}
          location={location}
        />

        <section className="page-header category">
          <div className="page-slider">
            <div className="slide">
              <div className="title-block">
                <div className="wrap">
                  <div className="inner">
                    <Breadcrumbs location={location} />
                    {headerTitle && <h1 className="title" dangerouslySetInnerHTML={{__html: headerTitle}} />}
                    {subTitle && <span className="sub-title" dangerouslySetInnerHTML={{ __html: decodeEntities(subTitle) }} />}
                    {description && <span className="short-description" dangerouslySetInnerHTML={{ __html: decodeEntities(description) }} />}
                  </div>
                </div>
              </div>

              {featuredImage &&
                <div className={`background-wrap contain`}>
                  <div className="background"><div className="inner"><Image src={featuredImage} /></div></div>
                  {featuredImage.title && <div className="image-title" dangerouslySetInnerHTML={{ __html: decodeEntities(slide.background.title) }} />}
                </div>
              }
            </div>
          </div>
        </section>

        <PostList
          posts={posts}
          title="Latest posts"
          pageContext={pageContext}
          categories={categories.nodes}
          //categoryFilter={categoryFilter}
          siteMetadata={wordpressWpSettings}
          pathPrefix="/blog/"
          showAuthor={showAuthor}
          dateFormat={date_format}
        />
      </Layout>
    )
  }
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    allWordpressPost: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
  pageContext: PropTypes.shape({
    currentPage: PropTypes.number,
    numPages: PropTypes.number,
  }),
}

export const pageQuery = graphql`
  query IndexQuery($limit: Int!, $skip: Int!) {
    wordpressWpSettings {
      title
      wordpressUrl
      blogSlug
      siteUrl
      date_format
    }
    siteSettings: wordpressAcfOptions(options: {}) {
      options {
        showAuthor
      }
    },
    thisCategoryPost: wordpressCategory(slug: {eq: "uncategorised"}) {
      description
      slug
      yoast {
        metaTitle: title
        metaDescription: metadesc
        meta_robots_noindex
        meta_robots_nofollow
      }
      acf {
        title
        subTitle
        featureImage {
          source_url
        }
      }
    }
    categoryFilter: allWordpressPost {
      nodes {
        categories {
          wordpress_id
          id
          name
          slug
          path
          id
        }
      }
    }
    allWordpressPost(
      sort: { fields: date, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          ...PostListFields
        }
      }
    }
    categories: allWordpressCategory(filter: { count: { gt: 0 } }) {
      nodes {
        name
        slug
        path
        featured_image_url {
          source_url
        }
        count
      }
    }
  }
`
